/* imports and utilities like animations and mixins should be defined here */
@use 'sass:math';
@import '@xpo-ltl/ngx-ltl-core/styles/variables';
@import '../assets/styles/custom/custom-variables';

// Layout styles
@import '../assets/styles/layout/grid-customization';

// Custom styles
@import '../assets/styles/custom/forms';
@import '../assets/styles/custom/custom-directives';
@import '../assets/styles/custom/ngx-override';

// TODO: Remove this styles once we use tabs for queues
.ncis {
  &-Card {
    &-marginTop {
      margin-top: math.div($xpo-contentSpacing, 2);
    }
  }
}

.ag-root-wrapper-body.ag-layout-normal {
  height: auto;
}

.instruction-history-grid {
  .ag-center-cols-viewport {
    overflow-x: hidden;
  }
}

.ag-body-horizontal-scroll-viewport {
  min-height: 17px !important;
}

.xpo-AgGrid .ag-column-hover {
  background-color: transparent !important;
}
