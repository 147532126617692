@use 'sass:math';
.ncis-FormGroup {
  display: flex;
  margin-bottom: math.div($xpo-contentSpacing, 2);
}

.label {
  opacity: 0.7;
}

.ncis-Customer-card {
  align-items: stretch;
  display: flex;

  .xpo-Card-header {
    height: 35px;

    .ncis-CustomerActions-align {
      align-items: center;
      display: flex;
    }
  }

  .ncis-FormGroup {
    align-items: normal;
    display: flex;
    padding: 0 $xpo-contentSpacing 0;
  }

  .xpo-Card-content {
    flex-grow: 0;

    &:not(.ncis-FormGroup--singleColumn) {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.ncis-GroupStatus-icon {
  margin-right: math.div($xpo-contentSpacing, 2);

  &--warn {
    color: $xpo-color--warn;
  }

  &--success {
    color: $xpo-color--success;
  }
}

// TODO: Try to move this .ncis-FormGroup
.ncis-FormGroup--singleColumn {
  .label {
    &.col {
      max-width: 35%;
    }
  }
}
