mat-chip.mat-chip {
  &.xpo-Chip--medium {
    font-size: $xpo-fontSize--medium;
    line-height: $xpo-lineHeight--medium;
    min-height: $xpo-lineHeight--medium;
    padding-left: 6px;
    padding-right: 6px;
  }
}

mat-drawer-content {
  &.xpo-TabDrawer-contentWrapper.mat-drawer-content {
    background-color: $xpo-white;
    padding: 0;
  }
}
